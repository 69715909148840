import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

export default function index(props) {
  const { scrollToItem, itemRefs } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <Typography variant="h4" gutterBottom>
          Investigations Help
        </Typography>
      </ListItem>
      <Divider />
      <ListItem disablePadding sx={{ mt: 4 }}>
        <ListItemText primary="Topics" />
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("filter")}>
          Filter
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("listing")}>
          Listing Card
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("listing_card_actions")}>
          Listing Card Actions
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ pl: 4 }}>
        <ListItemButton onClick={() => scrollToItem("listing_actions")}>
          Listing Actions
        </ListItemButton>
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["filter"] = el)}
        sx={{ marginTop: 5 }}
      >
        Filter
      </Divider>
      <ListItem>
        <Typography>TODO</Typography>
      </ListItem>
      <ListItem>
        <ListItemText primary="" secondary="" />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["listing"] = el)}
        sx={{ marginTop: 5 }}
      >
        Listing Card
      </Divider>
      <ListItem>
        <Typography>
          Each listing is represented by a card with the following data:
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemText primary="" secondary="" />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["listing_card_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Listing Card Actions
      </Divider>
      <ListItem>
        <Typography>TODO</Typography>
      </ListItem>
      <ListItem>
        <ListItemText primary="" secondary="" />
      </ListItem>
      <Divider
        ref={(el) => (itemRefs.current["listing_actions"] = el)}
        sx={{ marginTop: 5 }}
      >
        Listing Actions
      </Divider>
      <ListItem>
        <Typography>TODO</Typography>
      </ListItem>
      <ListItem>
        <ListItemText primary="" secondary="" />
      </ListItem>
    </List>
  );
}
