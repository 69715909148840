import * as React from "react";
import styled from "styled-components/macro";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import HelpIcon from "@mui/icons-material/Help";
import { Palette as PaletteIcon } from "@mui/icons-material";
import Themes from "./Themes";
import Help from "./Help";

const StyledSpeedDial = styled(SpeedDial)`
  position: fixed;
  right: ${(props) => props.theme.spacing(8)};
  bottom: ${(props) => props.theme.spacing(8)};
  z-index: 1;
`;

export default function FloatingMenu() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openThemes, setOpenThemes] = React.useState(false);
  const [openHelp, setOpenHelp] = React.useState(false);

  const actions = [
    {
      icon: <PaletteIcon />,
      name: "Themes",
      onClick: () => {
        setOpenThemes(true);
      },
    },
    {
      icon: <HelpIcon />,
      name: "Help",
      onClick: () => {
        setOpenHelp(true);
      },
    },
  ];

  return (
    <React.Fragment>
      <StyledSpeedDial
        ariaLabel="Theme and Help"
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            onClick={action.onClick}
            tooltipTitle={action.name}
          />
        ))}
      </StyledSpeedDial>
      <Themes
        open={openThemes}
        close={() => {
          setOpenThemes(false);
          setOpen(false);
        }}
      />
      <Help
        open={openHelp}
        close={() => {
          setOpenHelp(false);
          setOpen(false);
        }}
      />
    </React.Fragment>
  );
}
